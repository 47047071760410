@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply overflow-x-hidden font-ibm text-base font-normal leading-body text-prominent;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-ibm font-bold leading-base text-prominent;
    }
    h3,
    .h3 {
        @apply text-[1.375rem];
    }
}
